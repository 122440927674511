
.payment label{
    font-weight: 600;
    color: #000d6b;
}

.payment button{
    padding: 8px 15px;
    border-radius: 5px;
    background: #000d6b;
    color: #fff;
    border: none;
    outline: none;
}

@media only screen and (max-width:768px){
    .booking__form{
        width: 100%;
    }
    .booking__form input, .textarea, .booking__form select{
        font-size: 0.8rem;
    }
}