
.form__style{
    width: 450px;
    height: 550px;
    box-shadow: 8px 13px 30px -3px rgba(0,0,0,0.1);
    padding: 0 30px;
}
.register__btn{
    padding: 7px 15px;
    background: #000d6b !important;
    color: #fff !important;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 100%;
}

.img__size{
    width: 480px;
    height: 480px;
}

@media only screen and (max-width: 992px){
 
    .form__style{
        width: 400px;
        height: 540px;
       
    }
}

/*Resposive for mobile device*/
@media only screen and (max-width: 768px){
    .img__size{
        width: 335px;
        height: 280px;
    }
   
    .register__form{
        margin: auto;
    }

}