
.blog__publisher span i{
    color: #f9a826;
}

.blockquote{
    line-height: 35px;
}

.single__comment img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #000d6b;
    object-fit: cover;
}

.replay i{
    color: #f9a826;
}

.replay{
    color: #000d6b;
    font-weight: 600;
    cursor: pointer;
}

.leave__comment-form input, .leave__comment-form textarea{
    border: 1px solid rgba(0, 12, 107, 0.118);
}

.leave__comment-form textarea:focus{
    outline: none;
}

.comment__btn{
    background: #000d6b;
    color: #fff;
}

.comment__btn:hover{
    background: #000d6b;
    color: #fff;
}

.recent__blog-item h6 a{
    text-decoration: none;
    color: #000d6b;
    font-weight: 600;
}

@media only screen and (max-width:768px){
    .recent__post{
        margin-top: 30px;
    }

    .leave__comment-form input, .leave__comment-form textarea{
        font-size: 0.7rem;
    }

    .comment__btn{
        padding: 5px 10px;
        font-size: 0.8rem;
        margin-top: 0px !important;
    }

    .leave__comment-form h4{
        font-size: 1.2rem;
    }

    .single__comment img {
        width: 40px;
        height: 40px;
      }
    
      .comment__content p {
        font-size: 0.8rem;
        line-height: 1.4rem;
      }
    
      .blog__details p {
        font-size: 0.8rem;
        line-height: 1.4rem;
      }
}