

.car__item{
    border: 1px solid #7c8a9736;
    padding: 20px;
    border-radius: 5px;
}

.car__item-info span i{
    color: #f9a826;
    font-size: 1.1rem;
}

.car__item-btn{
    border-radius: 0;
    border: none;
    outline: none;
    background: #000d6b;
    padding: 8px 0;
   
}

.car__item-btn a{
    text-decoration: none;
    color: #fff;
    font-weight: 500;
}

.car__btn-details{
    background: #f9a826;
    border-radius: 0 4px 4px 0;

}

.car__btn-rent{
    border-radius: 4px 0 0 4px;
}

.car__item-content h4{
    font-size: 1.8rem;
}

.car__item-content h6{
    font-size: 1.2rem;
    font-weight: 600;
}

@media only screen and (max-width: 992px){
    .car__item h4{
        font-size: 1.1rem;
        margin-bottom: 0;
    }
    .car__item h6{
        font-size: 1rem;
        margin-top: 10px;
    }

    .car__item-info{
        flex-wrap: wrap;
    }

    .car__item-info span{
        font-size: 0.8rem;
        justify-content: space-between;
    }

    .car__item-btn{
        padding: 5px 10px;
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 768px){
    .car__item h4{
        font-size: 1rem;
       
    }
    .car__item h6{
        font-size: 0.9rem;
      
    }
    .car__item-info span{
        font-size: 0.7rem;
        
    }
    .car__item-info span i{
        font-size: 0.9rem;
        
    }
    .car__item-btn{
        font-size: 0.8rem;
    }
}